import React from "react";
import PropTypes from "prop-types";
import {
    Timeline, Text, Anchor
} from '@mantine/core'
import { DatabaseImport, TruckDelivery, FileArrowRight } from 'tabler-icons-react'
import api from "../../../../api";

function getTaskStatusColor(task) {
    let color = 'gray'

    if (task.task_type === 'SGL Outbound' || task.task_type === 'Ecommerce Outbound') {
        if (task.task_status === 'Error') {
            color = 'red'
        }
        if (task.task_status === 'Success') {
            color = 'green'
        }
    }

    if (task.task_type === 'System Log') {
        color = 'blue'
    }

    return color
}

function getTitle(task) {
    if (task.task_type === "SGL Outbound") {
        return "Biztalk File"
    }
    if (task.task_type === "Ecommerce Outbound") {
        return "Ecommerce Return"
    }
    return "System Log"
}

const ReturnViewTimeline = ({model, onViewTaskClick}) => {
    return <div>
        <Timeline bulletSize={28} lineWidth={2}>
            {model.order.id ? <Timeline.Item bullet={<DatabaseImport size={16} color='green'/>} title="Order Imported">
                <Text color='dimmed' size='sm'>Order imported from {model.order.source}</Text>
                <Text color='dimmed' size='sm'>{api.helpers.toLocalDateTime(model.order.created_at)}</Text>
            </Timeline.Item> : null }
            <Timeline.Item bullet={<TruckDelivery size={16} color='green'/>} title="Return Created">
                <Text color='dimmed' size='sm'>Return Created by {model.username}</Text>
                <Text color='dimmed' size='sm'>{api.helpers.toLocalDateTime(model.created_at)}</Text>
            </Timeline.Item>
            {model.tasks.map(task => {
                return <Timeline.Item key={task.id} bullet={<FileArrowRight size={16} color={getTaskStatusColor(task)}/>} title={getTitle(task)}>
                    {task.task_type === 'SGL Outbound' ? <Text color='dimmed' size='sm'>{task.message_1}</Text> : null}
                    {task.task_type === 'Ecommerce Outbound' ? <Text color='dimmed' size='sm'>{task.message_1}</Text> : null}
                    {task.task_type === 'System Log' ? <Text color='blue' size='sm'>System Log</Text> : null}
                    <Text color='dimmed' size='sm'>{api.helpers.toLocalDateTime(task.updated_at)} ({task.task_status})</Text>
                    <Anchor onClick={() => {onViewTaskClick(task)}}>View</Anchor>
                </Timeline.Item>
            })}
        </Timeline>
    </div>
}

ReturnViewTimeline.propTypes = {
    onViewTaskClick: PropTypes.func.isRequired,
    model: PropTypes.shape({
        order: PropTypes.object.isRequired,
        tasks: PropTypes.array.isRequired
    }).isRequired
}

export default ReturnViewTimeline