import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useDispatch} from 'react-redux'
import {
    TextInput, Box, Group, Button, Divider, Select, Textarea, ScrollArea
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
    NEW_CUSTOMER_CONNECTION_CREATED,
    CUSTOMER_CONNECTION_UPDATED,
    CUSTOMER_CONNECTION_DELETED
} from '../../redux/action'
import api from '../../api'

const ConnectionForm = ({initialValues,onCloseForm, customer}) => {
    const dispatch = useDispatch()

    const form = useForm({
        initialValues: initialValues
    })

    const [loading, setLoading] = useState(false)
    return (
        <Box mt={10}>
        <ScrollArea style={{height: 500}}>
            <Divider my='lg' size='lg'/>
            <form onSubmit={form.onSubmit(async (values) => {
                console.log(values)
                setLoading(true)
                let response
                if (values.id === '_new') {
                    response = await api.admin.createConnection(customer.id, values)
                } else {
                    response = await api.admin.updateConnection(customer.id, values.id, values)
                }
                const {code, message} = response
                if (code === 200) {
                    if (values.id === '_new') {
                        dispatch({type: NEW_CUSTOMER_CONNECTION_CREATED, payload: {customerId: customer.id, data: message}})
                    } else {
                        dispatch({type: CUSTOMER_CONNECTION_UPDATED, payload: {
                                customerId: customer.id,
                                optionId: values.id,
                                data: message
                            }})
                    }
                    setLoading(false)
                    onCloseForm()
                } else {
                    setLoading(false)
                }
            })}>
                <Group grow={true}>
                    <Select mt='lg'
                            data={[
                                { value: 'Biztalk Inbound Order', label: 'Biztalk Inbound Order' },
                                { value: 'Biztalk Outbound Return', label: 'Biztalk Outbound Return' },
                                { value: 'SGL Ecom', label: 'SGL Ecommerce' }
                            ]}
                            label="Connection Type"
                            {...form.getInputProps('connection_type')}
                    />
                    <Select mt='lg'
                            data={[
                                { value: 'Default', label: 'Default' }
                            ]}
                            label="Biztalk Schema"
                            {...form.getInputProps('biztalk_schema')}
                    />
                    <TextInput
                        label="Biztalk Sender"
                        placeholder=""
                        mt="lg"
                        {...form.getInputProps('biztalk_sender')}
                    />
                    <TextInput
                        label="Biztalk Receiver"
                        placeholder=""
                        mt="lg"
                        {...form.getInputProps('biztalk_receiver')}
                    />
                </Group>
                <Group grow={true}>
                    <TextInput
                        label="Endpoint"
                        placeholder=""
                        mt="lg"
                        {...form.getInputProps('endpoint')}
                    />
                    <TextInput
                        label="Username"
                        placeholder=""
                        mt="lg"
                        {...form.getInputProps('username')}
                    />
                    <TextInput
                        label={initialValues.has_short_token && initialValues.id !== '_new' ? 'Update Password' : 'Password'}
                        placeholder=""
                        mt="lg"
                        {...form.getInputProps('secure_short_token')}
                    />
                </Group>

                <Group grow={true}>
                    <Textarea
                        label={initialValues.has_long_token && initialValues.id !== '_new' ? 'Update API Token' : 'API Token'}
                        placeholder=""
                        mt="lg"
                        {...form.getInputProps('secure_long_token')}
                    />
                </Group>

                <Group position="left" mt="md">
                    <Button type="submit" color='sgl' loading={loading}>Save</Button>
                    <Button variant='subtle' color='sgl' loading={loading} onClick={() => {onCloseForm()}}>Close</Button>
                    {initialValues.id !== '_new' ? <Button variant='subtle' color='sgl' loading={loading} onClick={async () => {
                        setLoading(true)
                        await api.admin.deleteConnection(customer.id, initialValues.id)
                        dispatch({type: CUSTOMER_CONNECTION_DELETED, payload: {
                                customerId: customer.id,
                                optionId: initialValues.id
                            }})
                        setLoading(false)
                        onCloseForm()
                    }}>Delete</Button> : null}
                </Group>
            </form>
        </ScrollArea>
        </Box>
    )
}

ConnectionForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    onCloseForm: PropTypes.func.isRequired
}

export default ConnectionForm